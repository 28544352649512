import ky from 'ky';
import config from '../config/config';
import { getEncryptPersonId, getPersonId } from '../utils/encrypt-utils';
// eslint-disable-next-line import/no-cycle
import { getCustomerData } from '../store/model/main-model';

const secret = config.auth.loginConfig === 'natura_login' ? config.bffApi.clientSecret : config.api.clientSecretAuth;

const naturaAuth = () => {
  const customerData = getCustomerData();
  return ky.create({
    timeout: 50000,
    prefixUrl: config.api.naturaAuthUrl,
    headers: {
      Accept: '*/*',
      'Accept-Language': 'en-US,en;q=0.9',
      Connection: 'keep-alive',
      Origin: 'https://naturacldev-a.akamaihd.net',
      Referer: 'https://naturacldev-a.akamaihd.net/',
      'Sec-Fetch-Dest': 'empty',
      'Sec-Fetch-Mode': 'cors',
      'Sec-Fetch-Site': 'cross-site',
      tenant_id: config.bffApi.tenantId,
      customer_id: customerData.customer_id,
      customer_no: customerData.customer_no,
      client_id: config.api.clientIdAuth,
      client_secret: secret,
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      personId: getPersonId(),
      cnid: getEncryptPersonId(),
    },
  });
};
export default naturaAuth;
