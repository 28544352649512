import React, { useContext } from 'react';
import { I18nContext } from '../../config/i18n';
import Typography from '../typography/Typography';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';
import DialogTitle from '../dialog-title/DialogTitle';
import DialogContent from '../dialog-content/DialogContent';
import DialogActions from '../dialog-actions/DialogActions';
import config from '../../config/config';

const GenericError = ({ openModal = false, toClose }) => {
  const { translate } = useContext(I18nContext);
  const isNaturaArgentina = config.general.country === 'ar';
  const isNaturaMexico = config.general.country === 'mx';
  const isNaturaChile = config.general.country === 'ch';

  return (
    <>
      <Dialog
        maxWidth="sm"
        onEscapeKeyDown={toClose}
        open={openModal}
      >
        <DialogTitle>
          <Typography>
            {translate('blocklist-modal-title')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {translate('generic-error-modal-text')}
          </Typography>
          {isNaturaArgentina && (
            <Typography>
                {translate('generic-error-modal-contact-ar')}
            </Typography>
          )}
          {isNaturaMexico && (
            <Typography>
                {translate('generic-error-modal-contact-mx')}
            </Typography>
          )}
          {isNaturaChile && (
            <Typography>
                {translate('generic-error-modal-contact-ch')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={toClose}
            variant="text"
          >
            {translate('forgotPassword.modal.button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenericError;
