import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute({ component: Component, ...rest }) {
  const ssoToken = sessionStorage.getItem('sso_token');
  const locationURL = window.location.href;
  const verify = locationURL.includes('token-reactivation');
  if (verify) {
    const refreshToken = locationURL.split('code=').at(-1);
    localStorage.setItem('token-reactivation', refreshToken);
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!ssoToken) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        // authorized so return component
        return <Component {...props} />;
      }}
    />
  );
}

export default PrivateRoute;
