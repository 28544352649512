import { action, thunk } from 'easy-peasy';

// eslint-disable-next-line import/no-cycle
import fetcher from '../../api/fetcher';
// eslint-disable-next-line import/no-cycle
import bffFetcher from '../../api/bffFetcher';
// eslint-disable-next-line import/no-cycle
import mockFetcher from '../../api/mockFetcher';
// eslint-disable-next-line import/no-cycle
import naturaAuth from '../../api/naturaAuth';
import { pushUserData } from '../../data-layer/user';
import { pushInteraction } from '../../data-layer/interaction';
import { removeMaskPhone } from '../../utils/numbers-utils';
import config from '../../config/config';
import { generateHash } from '../../utils/encrypt-utils';

let customerData = {
  customer_id: null,
  customer_no: null,
};

export const setCustomerData = (data) => {
  customerData = data;
};

export const getCustomerData = () => customerData;

const initialState = {
  theme: localStorage.getItem('app_theme') || 'light',
  loading: true,
  personId: null,
  customerNo: null,
  personName: null,
  personBirthday: null,
  consultantUserId: null,
  personEmail: null,
  firstName: null,
  welcomeMessage: null,
  personFullName: null,
  personDigitalSpaceName: null,
  openReactivationModal: false,
  reactivationModelMessage: 'success',
  digitalStoreId: null,
  nickName: null,
  personPhoneNumber: null,
  personCnCode: '',
  personImage: '',
  comemorativeImage: '',
  personIdNumber: '5555',
  personGender: null,
  personState: '',
  levelCN: '',
  personAddress: [{ zipCode: '', location: '' }],
  reservedDate: null,
  imageCN: null,
  newImageCN: null,
  imageWelcome: null,
  newImageWelcome: null,
  status: 'ACTIVE', // 'DISABLED'
  error: false,
  messageErrorResetPassword: null,
  getState: action((state) => state),
  dataBanner: [],
  dataAlert: [],
  loadingDataBanner: true,
  loadingDataAlert: true,
  whatsWappNumber: null,
  evaluationRate: 0,
  evaluationAmount: null,
  instagramLink: '',
  facebookLink: '',
  channelYoutubeLink: '',
  showAlert: {},
  introMessage: null,
  showcaseList: [],
  nameTent: '',
  categorySelected: null,
  phoneHome: '',
  lastName: '',
  loadingFetchItemsShowCase: false,
};

export const mainModel = {
  ...initialState,
  resetStoreMain: action((state) => {
    Object.assign(state, initialState);
  }),
  setLoadingFetchItemsShowCase: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loadingFetchItemsShowCase = payload;
  }),
  setCategorySelected: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.categorySelected = payload;
  }),
  setNameTent: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.nameTent = payload;
  }),
  setShowAlert: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.showAlert = payload;
  }),
  setShowcaseList: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.showcaseList = payload;
  }),
  setNewImageCN: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.newImageCN = payload;
  }),
  setNewImageWelcome: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.newImageWelcome = payload;
  }),
  changeTheme: action((state) => {
    localStorage.setItem('app_theme', state.theme === 'dark' ? 'light' : 'dark');
    // eslint-disable-next-line no-param-reassign
    state.theme = state.theme === 'dark' ? 'light' : 'dark';
  }),
  setWelcomeMessage: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.welcomeMessage = payload;
  }),
  setChannelYoutubeLink: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.channelYoutubeLink = payload;
  }),
  setFacebookLink: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.facebookLink = payload;
  }),
  setInstagramLink: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.instagramLink = payload;
  }),
  setImageCN: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageCN = payload;
  }),
  setImageWelcome: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageWelcome = payload;
  }),
  setPersonName: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.personName = payload;
  }),
  setStatus: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.status = payload;
  }),
  setOpenReactivationModal: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.openReactivationModal = payload.status;
    // eslint-disable-next-line no-param-reassign
    state.reactivationModelMessage = payload.message;
  }),
  setNickName: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.nickName = payload;
  }),
  setWhatsWappNumber: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.whatsWappNumber = payload;
  }),
  addPersonData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.personAddress = payload.address;
    // eslint-disable-next-line no-param-reassign
    state.personName = payload.fullName;
    // eslint-disable-next-line no-param-reassign
    // state.personFullName = payload.fullName;
    // eslint-disable-next-line no-param-reassign
    state.firstName = payload.firstName;
    // eslint-disable-next-line no-param-reassign
    // state.personIdNumber = payload.document[0]?.number;
    // eslint-disable-next-line no-param-reassign
    state.personBirthday = payload.birthDate;
    // eslint-disable-next-line no-param-reassign
    state.personGender = payload.genderId;
    // eslint-disable-next-line no-param-reassign
    state.personEmail = payload.email[0]?.email;
    // eslint-disable-next-line no-param-reassign
    state.personPhoneNumber = payload.phone;
    // eslint-disable-next-line no-param-reassign
    // state.personDigitalSpaceName = payload.digitalStoreName;
    // eslint-disable-next-line no-param-reassign
    state.welcomeMessage = payload.welcomeMessage;
    // eslint-disable-next-line no-param-reassign
    state.nickName = payload.nickName;
    // eslint-disable-next-line no-param-reassign
    // state.imageCN = payload.imageCN;
    // eslint-disable-next-line no-param-reassign
    // state.imageWelcome = payload.imageWelcom;
    if (payload.socialNetwork) {
      if (payload.socialNetwork.facebook) {
        // eslint-disable-next-line no-param-reassign
        state.facebookLink = payload.socialNetwork.facebook;
      }
      if (payload.socialNetwork.instagram) {
        // eslint-disable-next-line no-param-reassign
        state.instagramLink = payload.socialNetwork.instagram;
      }
      if (payload.socialNetwork.youtube) {
        // eslint-disable-next-line no-param-reassign
        state.channelYoutubeLink = payload.socialNetwork.youtube;
      }
    }
  }),
  addDigitalStoreData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.status = payload.status;
    // eslint-disable-next-line no-param-reassign
    state.reservedDate = payload.reservedDate;

    // eslint-disable-next-line no-param-reassign
    state.digitalStoreId = payload.digitalStoreId;
  }),
  setLoading: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loading = payload;
  }),
  addLoginData: action((state, payload) => {
    const { mainPersonIdParam } = config.general;
    const newStatus = payload.c_natg_storeStatus === 0 || payload.c_natg_storeStatus === 4 ? 'INATIVE' : 'ACTIVE';
    // eslint-disable-next-line no-param-reassign
    state.status = newStatus;
    sessionStorage.setItem('consultantStatus', newStatus);
    // eslint-disable-next-line dot-notation
    // eslint-disable-next-line no-param-reassign, max-len
    state.personId = payload[`${mainPersonIdParam}`] || payload.c_natg_personNumber || payload.personId || payload.c_natg_personId || payload.customer_no || new Error('Missing field PERSON_ID');
    sessionStorage.setItem('personId', state.personId);
    // eslint-disable-next-line no-param-reassign, max-len
    state.customerNo = payload.customer_no || payload.personId || new Error('Missing field customer_no');
    // eslint-disable-next-line no-param-reassign, max-len
    state.customerId = payload.customer_id || new Error('Missing field customer_id');
    // eslint-disable-next-line no-param-reassign, max-len
    state.personIdCn = payload.personId || payload.c_natg_personId || payload.customer_no || payload.c_natg_personNumber || new Error('Missing field PERSON_ID');
    // eslint-disable-next-line no-param-reassign
    state.personName = payload.family_name || `${payload.first_name} ${payload.last_name}`;
    // eslint-disable-next-line no-param-reassign
    state.lastName = payload.last_name;
    // eslint-disable-next-line no-param-reassign
    state.personCnCode = payload.c_natg_personNumber;
    // eslint-disable-next-line no-param-reassign
    state.personBirthday = payload.birthday;
    // eslint-disable-next-line no-param-reassign
    state.personEmail = payload.email;
    // eslint-disable-next-line no-param-reassign
    state.consultantUserId = payload.c_mercadoPago_ConsultantRefreshToken?.split('-').at(-1)
      || payload.c_mercadoPago_ConsultantUserId || payload.c_mp_customer_id;
    // eslint-disable-next-line no-param-reassign
    state.personDigitalSpaceName = payload.c_natg_userId;
    // eslint-disable-next-line no-param-reassign
    state.welcomeMessage = payload.c_natg_introHeader;
    // eslint-disable-next-line no-param-reassign
    state.whatsWappNumber = payload.c_natg_whatsappPhone;
    // eslint-disable-next-line no-param-reassign
    state.instagramLink = payload.c_natg_instagramLink;
    // eslint-disable-next-line no-param-reassign
    state.evaluationRate = payload.c_natg_cnRating;
    // eslint-disable-next-line no-param-reassign
    state.personFullName = `${payload.first_name} ${payload.last_name}`;
    // eslint-disable-next-line no-param-reassign
    state.lastName = payload.last_name;
    // eslint-disable-next-line no-param-reassign
    state.personGender = payload.gender;
    // eslint-disable-next-line no-param-reassign
    state.personPhoneNumber = payload.phone_mobile;
    // eslint-disable-next-line no-param-reassign
    state.nickName = `${payload.first_name} ${payload.last_name}`;
    // eslint-disable-next-line no-param-reassign
    state.nameTent = `${payload.c_natg_displayFirstName || ''} ${payload.c_natg_displayLastName || ''}`.trim();
    // eslint-disable-next-line no-param-reassign
    state.introMessage = payload.c_natg_intro1;
    // eslint-disable-next-line no-param-reassign
    state.imageCN = payload.c_natg_picture1;
    // eslint-disable-next-line no-param-reassign
    state.imageWelcome = payload.c_natg_picture2;
    // eslint-disable-next-line no-param-reassign
    state.categorySelected = payload.c_natg_showcaseCategory;
    // eslint-disable-next-line no-param-reassign
    state.personIdNumber = payload.c_natg_cpf;
    // eslint-disable-next-line no-param-reassign
    state.phoneHome = payload.phone_home;
    // eslint-disable-next-line no-param-reassign
    state.levelCN = payload.c_natg_cnLevel;

    setCustomerData({
      customer_id: state.customerId,
      customer_no: state.customerNo,
    });
  }),
  removeLoginData: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.personId = null;
    // eslint-disable-next-line no-param-reassign
    state.personName = null;
    // eslint-disable-next-line no-param-reassign
    state.personBirthday = null;
    // eslint-disable-next-line no-param-reassign
    state.personEmail = null;
    // eslint-disable-next-line no-param-reassign
    state.personDigitalSpaceName = null;
    // eslint-disable-next-line no-param-reassign
    state.welcomeMessage = null;
    // eslint-disable-next-line no-param-reassign
    state.whatsWappNumber = null;
    // eslint-disable-next-line no-param-reassign
    state.instagramLink = null;
  }),
  setMessageErrorResetPassword: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.messageErrorResetPassword = payload;
  }),
  setError: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.error = payload;
    // eslint-disable-next-line no-param-reassign
    state.personId = null;
    // eslint-disable-next-line no-param-reassign
    state.personName = null;
    // eslint-disable-next-line no-param-reassign
    state.personBirthday = null;
  }),
  setErrorSection: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.errorSection = payload;
    // eslint-disable-next-line no-param-reassign
    state.personId = null;
    // eslint-disable-next-line no-param-reassign
    state.personName = null;
    // eslint-disable-next-line no-param-reassign
    state.personBirthday = null;
  }),
  setDataBanner: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.dataBanner = payload;
  }),
  setDataAlert: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.dataAlert = payload;
  }),
  setLoadingDataAlert: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loadingDataAlert = payload;
  }),
  setLoadingDataBanner: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loadingDataBanner = payload;
  }),

  saveChanges: thunk(async (actions, payload, { getState, getStoreActions }) => {
    actions.setLoading(true);
    const {
      imputName,
      imputWelcomeMessage,
      imputFacebook,
      imputInstagram,
      imputYoutube,
      imputWhatsappPhone,
    } = payload;
    const phoneNumberWithoutMask = removeMaskPhone(imputWhatsappPhone);
    const {
      personId, customerNo, newImageCN, newImageWelcome, categorySelected,
    } = getState();
    const { main } = getStoreActions();
    const indentifier = config.general.company === 'natura' ? customerNo : personId;
    const imputNameSplit = imputName.split(' ');
    const firstName = imputNameSplit.length > 0 ? imputNameSplit[0] : '';
    let lastName = '';

    if (imputNameSplit.length > 1) {
      lastName = imputNameSplit.slice(1, imputNameSplit.length).join(' ');
    }

    const json = {
      welcomeMessage: imputWelcomeMessage,
      firstName,
      lastName,
      socialNetwork: {
        facebook: imputFacebook,
        instagram: imputInstagram,
        youtube: imputYoutube,
      },
      whatsappPhone: phoneNumberWithoutMask,
      showcaseCategory: categorySelected && categorySelected.id ? categorySelected.id : '',
    };

    if (newImageCN) {
      json.imageCN = `/9j/${newImageCN}`;
    }

    if (newImageWelcome) {
      json.imageWelcome = `/9j/${newImageWelcome}`;
    }

    mockFetcher().patch(`cn-data/${indentifier}`, {
      json,
      headers: { authorization: `Bearer ${sessionStorage.getItem('sso_token')}` },
    }).json()
      .then(() => {
        actions.setNewImageCN(null);
        actions.setNewImageWelcome(null);
        actions.setImageCN(newImageCN);
        actions.setImageWelcome(newImageWelcome);
        actions.setWelcomeMessage(imputWelcomeMessage);
        actions.setNameTent(imputName);
        actions.setChannelYoutubeLink(imputYoutube);
        actions.setFacebookLink(imputFacebook);
        actions.setInstagramLink(imputInstagram);
        actions.setWhatsWappNumber(phoneNumberWithoutMask);
        actions.setLoading(false);
        main.handleNaturaLogin();
      })
      .catch((err) => {
        actions.setError(true);
        actions.setLoading(false);
        console.error('error: -> ', err.message);
      });
  }),
  saveChangesAddress: thunk(async (actions, payload, { getState, getStoreActions }) => {
    actions.setLoading(true);
    const {
      country,
      imputNeighborhood,
      imputStreet,
      imputNumber,
      imputState,
      //  imputPostalCode,
      imputFloorAndApartment,
      imputCity,
    } = payload;
    const { personId, customerNo, lastName } = getState();
    const { main } = getStoreActions();
    const indentifier = config.general.company === 'natura' ? customerNo : personId;

    const json = {
      address: [{
        location: imputStreet,
        neighborhood: imputNeighborhood,
        addressId: 1,
        countryCode: country,
        contactLastName: lastName,
        complement: imputFloorAndApartment,
        number: imputNumber,
        city: imputCity,
        state: imputState,
      }],
    };

    mockFetcher().patch(`cn-data/${indentifier}`, {
      json,
      headers: { authorization: `Bearer ${sessionStorage.getItem('sso_token')}` },
    }).json()
      .then(() => {
        actions.setLoading(false);
        main.handleNaturaLogin();
      })
      .catch((err) => {
        actions.setError(true);
        actions.setLoading(false);
        console.error('error: -> ', err.message);
      });
  }),
  handleShowcase: thunk(async (actions, payload, { getState }) => {
    const { start = 0 } = payload;
    const { personId, customerNo, showcaseList } = getState();
    const indentifier = config.general.company === 'natura' ? customerNo : personId;
    fetcher().get(`catalogs?personId=${indentifier}&start=${start}&count=100`).json()
      .then((data) => {
        actions.setLoadingFetchItemsShowCase(true);
        actions.setShowcaseList(data && data.data ? [...showcaseList, ...data.data] : []);
        actions.setError(false);
        const newLength = [...showcaseList, ...data.data].length;
        if (newLength >= Number(data.total)) {
          actions.setLoadingFetchItemsShowCase(false);
        } else {
          actions.handleShowcase({ start: newLength });
        }
      })
      .catch((err) => {
        //    actions.setError(true);
        console.error('error: -> ', err.message);
      });
  }),
  handleLogin: thunk(async (actions) => {
    fetcher().post('auth', { json: { token: sessionStorage.getItem('sso_token') } }).json()
      .then((data) => {
        actions.addLoginData(data);
        pushUserData(data);
      })
      .catch((err) => {
        actions.setError(true);
        console.error('error: -> ', err.message);
        actions.handleLogout();
        window.location.href = '/logout';
      });
  }),
  handleChangeStatusShop: thunk(async (actions, payload) => {
    actions.setStatus('ACTIVE');
    const dataBody = {
      storeStatus: payload.storeStatus,
      consultantRefreshToken: payload.consultantRefreshToken,
      needSendToPaaS: true,
    };

    const hashEmail = generateHash(payload.personEmail);
    fetcher().patch(`cn-data/${payload.personId}`, { json: dataBody, headers: { email_to_mp: hashEmail } })
      .then(() => {
        const consultantStatus = sessionStorage.getItem('consultantStatus');
        const newStatus = consultantStatus === 'INATIVE' ? 'ACTIVE' : 'INATIVE';
        sessionStorage.setItem('consultantStatus', newStatus);
      })
      .catch((err) => {
        actions.setStatus('INATIVE');
        console.error('error: -> ', err.message);
        throw new Error(err.message);
      });
  }),
  handleBffLogin: thunk(async (actions, payload) => {
    const username = payload.login.trim();
    const userEncode = Buffer.from(`${username}:${payload.password}`, 'utf8')
      .toString('base64');
    try {
      const response = await bffFetcher().post('customers/auth', {
        json: { type: 'credentials' },
        headers: { authorization: `Basic ${userEncode}` },
      });
      const data = await response.json();
      const ssoToken = response.headers.get('Authorization').replace('Bearer ', '');
      const userResponse = {
        ...data,
        customer_no: data.customer_no,
        personName: `${data.first_name} ${data.last_name}`,
        personBirthday: data.birthday,
        personEmail: data.email,
      };
      if (!userResponse.c_natg_cfIsCn) {
        throw new Error('invalid consultant');
      }

      sessionStorage.setItem('sso_token', ssoToken);
      actions.addLoginData(userResponse);
      pushUserData(userResponse);
    } catch (e) {
      if (e && e.response) {
        const errorJson = await e.response.json();
        if (errorJson
          && errorJson.Message
          && errorJson.Message.Custom
          && errorJson.Message.Custom.fault
          && errorJson.Message.Custom.fault.arguments
          && errorJson.Message.Custom.fault.arguments.statusMessage
          // eslint-disable-next-line max-len
          && errorJson.Message.Custom.fault.arguments.statusMessage.includes('natg_passwordAlreadyResetedPostMigration = true')) {
          actions.setMessageErrorResetPassword(true);
          actions.setError(true);
        } else {
          actions.setError(true);
        }
      } else {
        actions.setError(true);
      }

      console.error('error bff: -> ', e.message);
    }
  }),
  handleNaturaLogin: thunk(async (actions) => {
    naturaAuth().post('auth', {
      json: { type: 'refresh' },
      headers: { authorization: `Bearer ${sessionStorage.getItem('sso_token')}` },
    }).json()
      .then((data) => {
        if (!data.c_natg_cfIsCn) {
          throw new Error('invalid consultant');
        }
        actions.addLoginData(data);
        pushUserData(data);
      })
      .catch((err) => {
        actions.handleLogout();
        actions.setErrorSection(true);
        console.error('error: -> ', err.message);
      });
  }),
  getCnData: thunk(async (actions, payload) => {
    if (config.general.useGetDataInHome === 'false') {
      actions.setLoading(false);
      return;
    }
    actions.setLoading(true);
    //   `cn-data/${payload.personId}?relations=documents%2Cemails&countryId=${general.country}&brand=${general.brand}`,
    mockFetcher().get(
      `cn-data/${payload.customerNo}?relations=documents%2Cemails`,
    )
      .json()
      .then((data) => {
        actions.setLoading(false);
        actions.addPersonData(data);
      })
      .catch((err) => {
        actions.setLoading(false);
        console.error('error: -> ', err.message);
      });
  }),
  requestBffPasswordReset: thunk(async (actions, payload) => {
    actions.setLoading(true);
    const email = payload.email.trim();
    bffFetcher().post('Account-PasswordResetToken', {
      json: {
        docId: email,
        channel: 'email',
      },
    }).json()
      .then(() => { })
      .catch((err) => {
        actions.setError(true);
        console.error('error bff: -> ', err.message);
      })
      .finally(() => {
        actions.setLoading(false);
      });
  }),
  handleLogout: thunk(async (actions) => {
    pushInteraction('logout');
    actions.removeLoginData();
    actions.setError(false);
    actions.setErrorSection(false);
    sessionStorage.removeItem('sso_token');
  }),
  handleDataBaner: thunk(async (actions) => {
    bffFetcher().get('Page-ShowJson?cid=home-painel-cnd', {
      headers: { authorization: `Bearer ${sessionStorage.getItem('sso_token')}` },
    }).json()
      .then((data) => {
        const dataBanner = data.components.map((e) => {
          if (e.entity === 'multiplesBanners') {
            return e.multiplesBanners.map((b) => b.simpleBanner);
          } if (e.entity === 'featuredItemsShowcase') {
            return e.featuredItems.map((b) => b.featuredItem);
          }
          return null;
        }).filter((a) => a);
        actions.setDataBanner(dataBanner.length > 1 ? [...dataBanner[0], ...dataBanner[1]] : dataBanner[0]);
        actions.setLoadingDataBanner(false);

        const dataAlerts = data.components.filter((e) => e.entity === 'gsSliderTextContainer').map((e) => e.sliders);
        let dataAlertsArray = [];
        dataAlerts.forEach((element) => {
          element.forEach((e) => {
            if (e.sliders) {
              dataAlertsArray = [...dataAlertsArray, ...e.sliders];
            } else {
              dataAlertsArray.push(e);
            }
          });
        });
        actions.setDataAlert(dataAlertsArray);
        actions.setLoadingDataAlert(false);
      })
      .catch((err) => {
        // actions.setError(true);
        console.error('error: -> ', err.message);
        actions.setLoadingDataBanner(false);
      });
  }),
};

export default mainModel;
